<template>
    <transition name="show">
        <div class="bio_link_layout">
            <div class="page_inner">
                <div class="sec_left" :class="{'no_padding_right': previewAllowedRoutes === false}">

                    <div class="left_inner">
                        <transition name="slide">
                            <router-view></router-view>
                        </transition>
                    </div>
                </div>

                <transition name="show">
                    <template v-if="previewAllowedRoutes">
                        <div class="sec_right">
                            <div class="right_inner">
                                <bio-preview></bio-preview>
                            </div>
                        </div>
                    </template>
                </transition>

            </div>

        </div>
    </transition>
</template>
<script>

import { mapGetters } from 'vuex'
import BioPreview from './snippets/BioPreview'

export default ({

  components: {
    BioPreview
  },

  async created () {
    if (!this.getProfile.email) {
      this.getUserProfile()
    }
  },

  computed: {
    ...mapGetters(['getProfile']),
    previewAllowedRoutes () {
      const whitelistRoutes = [
        'content_social_block',
        'content_links_block',
        'content_rss_block',
        'bio_finalize',
        'select_content',
        'content_audio_block',
        'content_video_block',
        'content_card_block',
        'content_text_block'
      ]
      return (whitelistRoutes.indexOf(this.$route.name) >= 0)
    }
  },
  beforeDestroy () {
  }

})

</script>
